





















































import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator'
import RangeDatePicker from '@/components/ui/RangeDatePicker.vue'
import RangerTimePicker from '@/components/ui/RangerTimePicker.vue'
import { FiltroDeAniversariante } from '@/models'
import { nextTick } from '@/shareds/utils'
import SeletorDeProfissao from '@/components/cliente/SeletorDeProfissao.vue'
import { limparFiltroDosCamposDeAniversariante } from '@/shareds/cliente-shareds'

@Component({
	components: {
		RangeDatePicker,
		RangerTimePicker,
		SeletorDeProfissao,
	},
})
export default class CamposDeFiltrosDeAniversariantes extends Vue {
	@Prop({ type: Boolean, default: false }) carregando!: boolean
	@PropSync('value', {
		default: () => ({
			periodo: 'dia',
			cliente: null,
			lojaId: '',
			genero: '',
			profissao: null,
			somenteComCashback: false,
		}),
	}) filtro!: FiltroDeAniversariante
	@Prop({ type: String, default: 'Gênero' }) placeholderGenero!: string;	
	mostra: boolean | null = null
	genero = ['MASCULINO', 'FEMININO', 'OUTROS']
	periodos = [
		{ text: 'Dia', value: 'dia' },
		{ text: 'Semana', value: 'semana' },
		{ text: 'Mês', value: 'mes' },
	]

	mostrar() {
		this.mostra = true
	}

	ocultar() {
		this.mostra = false
	}

	limparFiltros() {
		this.$emit('limpar')
		this.$emit('input', limparFiltroDosCamposDeAniversariante())
	}

	@Watch('paginacao', { deep: true, immediate: true })
	onChangePaginacao() {
		this.$emit('buscar', this.filtro)
	}

	async aplicarFiltro() {
		await nextTick()
		this.$emit('buscar', this.filtro)
	}

}
